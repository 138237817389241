import React from "react";
import { Link } from "react-router-dom";

export default function App() {

  return (
    <div id="">
      <div id="container">
        <div id="left">
          
          <img
            id="logo"
            srcset="/images/Logo@2x.png 2x, /images/Logo.png 1x"
            src="/images/Logo.png"
            alt="Breethr"
          />

          <h1 id="headline">
            <p id="headline-1">Life is demanding. </p>
            <p>
              Take a <span id="headline-2">Breethr.</span>
            </p>
          </h1>

          <p id="copy">
            Breethr offers quick, easy breathing exercises that you can do
            practically anytime and anywhere. Breathing exercises are the
            easiest way to re-center when life seems unbalanced.
          </p>
          
          <a href="https://apps.apple.com/us/app/breethr-breathing-exercises/id1565078313" target="_blank" rel="noreferrer"><img id="appStore" src="/images/appStore.png" alt="App Store" /></a>
        </div>

        <div id="right">
          <img
            id="illustration"
            src="../images/Illustration.png"
            alt="meditation"
          />
        </div>
      </div>
      <div id="footer">
        <div>
          <a
            href="https://instagram.com/breethr.app"
            target="_blank"
            rel="noreferrer"
          >
            <img id="social" src="/images/instagram.png" alt="instagram" />
          </a>
          <a
            href="https://tiktok.com/@breethr.app"
            target="_blank"
            rel="noreferrer"
          >
            <img id="social" src="/images/tiktok.png" alt="tiktok" />
          </a>
        </div>
        <div id="copyright">&#169; Copyright 2021, Little By Little Labs, LLC</div>

        <div>
          <Link to="/terms">Terms of Service</Link> and{" "}
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
}
