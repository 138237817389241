import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./components/App";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import "./styles.css"

const rootElement = document.getElementById("root");

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/" component={App} exact />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
      </Switch>
    </BrowserRouter>
  </StrictMode>,
  rootElement
);
