
export default function Terms() {
  return (
    <div id="TOS">
      <p style={{ textAlign: "right" }}>
        <span>Updated: 05/19/2021</span>
      </p>
      <h2 style={{ textAlign: "center" }}>
        <strong>TERMS OF SERVICE</strong>
      </h2>
      <p>
        <span>
          Please carefully read these Terms of Service (&ldquo;Terms&rdquo;,
          &ldquo;Terms of Use&rdquo;, &ldquo;Terms of Service&rdquo;
          &ldquo;Terms and Conditions&rdquo;) before using the mobile
          application Breethr (the &ldquo;Service(s)&rdquo;,
          &ldquo;Product&rdquo;, &ldquo;App&rdquo;), created by Little by Little
          Labs, LLC., and/or Breethr and Breethr.com (&ldquo;Company&rdquo;,
          &ldquo;Us&rdquo;, &ldquo;Our&rdquo;, &ldquo;We&rdquo;).
        </span>
      </p>
      <p>
        <span>
          This document describes the Terms between Little by Little Labs, LLC.,
          and you, the User, (&ldquo;You&rdquo;, &ldquo;User&rdquo;) through our
          website (Breethr.com) and mobile application (Breethr). This document
          is a legally binding agreement concerning your access to and use of
          our App.
        </span>
      </p>
      <p>
        <span>
          By downloading, accessing or using the Service, you accept, agree and
          will comply with these Terms. If you do not agree to any part of these
          Terms, you do not have permission to access the Service. Do not
          continue to use the Service if you do not agree to the Terms of
          Service.
        </span>
      </p>
      <p>
        <span>
          We may revise or update these Terms at any time. Changes will be noted
          by an update in date at the top of this page. All changes are
          effective immediately from when they are posted and apply to any use
          of any of our Services, including the website or app, thereafter.
          Continued use of our Service constitutes acceptance of the changes.
          Please check our website regularly for updates.
        </span>
      </p>
      <br />
      <p>
        <strong>HEALTH AND USER DISCLAIMER</strong>
      </p>
      <br />
      <p>
        <span>
          Little by Little Labs, LLC., and Breethr are not healthcare or medical
          device providers. We do not give any type of medical or psychological
          advice and none of the content provided by our Services are to be
          misconstrued as medical or psychological advice. By using this app,
          you accept full responsibility for your wellbeing and any
          health-related circumstances that may occur or develop from its use.
        </span>
        <span>
          <br />
        </span>
        <span>
          <br />
        </span>
        <span>
          By using our Services, you agree that you have consulted with a
          physician or other healthcare provider as to the safety of performing
          breathing exercises. You should only receive medical and health advice
          from a healthcare professional.&nbsp;
        </span>
      </p>
      <p>
        <span>
          Little By Little Labs, LLC., assumes no responsibility for illness or
          injury due to the use of its Service. Little By Little Labs, LLC.,
          will not be held liable for any damages or injuries that may occur
          either directly or indirectly from use of the App.
        </span>
      </p>
      <p>
        <span>
          Breathing exercises can cause adverse health effects in certain
          individuals, including but not limited to shortness of breath,
          lightheadedness, and rapid heart rate. If any health related cause for
          concern occurs, immediately stop use of the app and seek medical care.{" "}
        </span>
        <span>
          <br />
        </span>
        <span>
          <br />
        </span>
        <span>
          Little By Little Labs, LLC., makes no claims or guarantees any
          physical or mental health benefits in any individual circumstance from
          use of the App.
        </span>
      </p>
      <br />
      <p>
        <strong>SUBSCRIPTIONS</strong>
      </p>
      <br />
      <p>
        <span>
          Users may use limited features on the App through our
          &ldquo;free&rdquo; service. Users may also sign up for a monthly
          subscription, (&ldquo;Subscription&rdquo;) to gain access to the full
          App through the Apple App Store or iTunes, which includes all our
          premium content. Billing will be set on a monthly cycle and will renew
          automatically.
        </span>
      </p>
      <p>
        <span>
          You may cancel your subscription at any time through the App Store. We
          do not offer refunds, except when required by law, including for
          cancellations made at the beginning of a billing cycle.
        </span>
      </p>
      <p>
        <span>
          All payments made through iTunes or the App Store are controlled by
          Apple.
        </span>
      </p>
      <br />
      <p>
        <strong>INTELLECTUAL PROPERTY</strong>
      </p>
      <br />
      <p>
        <span>
          Breethr, and its original content, features and functionality are the
          exclusive property of Little by Little Labs, LLC., and its licensors.
          Unless otherwise stated, Little by Little Labs, LLC., and/or Breethr
          own the intellectual property rights for all material on Breethr and
          all Little by Little Labs, LLC., Apps and Services. All intellectual
          property rights are reserved.
        </span>
      </p>
      <br />
      <p>
        <span>You may not:</span>
      </p>
      <ul>
        <li>
          <span>
            Republish material from Little by Little Labs, LLC., or Breethr
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            Sell, rent or sub-license material from Breethr
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            Reproduce, duplicate or copy material from Breethr
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>Redistribute content from Breethr</span>
        </li>
      </ul>
      <br />
      <p>
        <strong>LIMITATION OF DAMAGES</strong>
      </p>
      <br />
      <p>
        <span>
          In no event shall Little by Little Labs, LLC., or Breethr, its
          licensors, directors or employees be liable for any direct, indirect,
          special, incidental, punitive, consequential damages, including but
          not limited to personal injury, pain and suffering, emotional
          distress, of any kind that may result from, or in connection to, the
          use of the App or inability to use the App, to the fullest extent
          permitted by applicable law.
        </span>
      </p>
      <br />
      <p>
        <strong>RELEASE AND INDEMNITY</strong>
      </p>
      <br />
      <p>
        <span>
          The User hereby releases and waives all claims and/or liability
          against Little by Little Labs, LLC., and/or Breethr, its licensors,
          directors or employees that may result from the use of the App. The
          content displayed on this Website and App provides no guarantees,
          conditions or warranties as to its accuracy. The User agrees to
          defend, indemnify and hold harmless Little by Little Labs, LLC.,
          and/or Breethr, its licensors, directors or employees from any and all
          claims or liability, including cost and attorney fees arising from or
          in connection with the use of the App or failure to comply with
          applicable laws.
        </span>
      </p>
      <br />
      <p>
        <strong>VARIATION</strong>
      </p>
      <br />
      <p>
        <span>
          Little by Little Labs, LLC., and/or Breethr shall have the right in
          its absolute discretion at any time and without notice to amend,
          remove or vary the Services and/or any page of this website and the
          Breethr app.
        </span>
      </p>
      <p>
        <span>Any questions may be directed to: </span>
        <a href="mailto:info@littlebylittlelabs.com">
          <span>info@littlebylittlelabs.com</span>
        </a>
        <span>.</span>
      </p>
      <p>&nbsp;</p>
    </div>
  );
}
